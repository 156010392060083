import {
    filterReWritable,
    sanitizeTerm,
    sanitizeTermForGraphite,
    sanitizeTermForNewRelic,
} from '@splunk/olly-utilities/lib/LuceneSanitizer/luceneSanitizer';
import templateUrl from './plotdisplay.tpl.html';
import formulateTooltipTemplateUrl from '../../../../app/charting/chartbuilder/sections/formulaTooltip.tpl.html';
import metricFilterItemTemplateUrl from './metricFilterItemTemplate.tpl.html';
import { convertMSToString } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { createHistogramManipulationName } from './util/histogramMetricUtility';
import { merge, omit } from 'lodash';
import { getProgramArgsForDashboardInTime } from '../../utils/programArgsUtils';
import { CALENDAR_WINDOW_CONSTANTS } from '../../analytics/util/calendarWindowConstants';
import { DEFAULT_PERCENTILE_VALUE } from '../../analytics/block/PERCENTILE/PercentileController';

angular.module('signalview.chartbuilder').directive('plotdisplay', [
    '$timeout',
    '$sanitize',
    'chartbuilderUtil',
    'analyticsService',
    'signalboostUtil',
    '$q',
    '$log',
    'plotUtils',
    'regExStyles',
    'programTextUtils',
    'sourceFilterService',
    'rollupsFromMetricType',
    'colorAccessibilityService',
    'plotSignalSuggestionService',
    'userAnalytics',
    'PLOT_OPTION_STRINGS',
    'detectorService',
    'urlOverridesService',
    'detectorSettingsModal',
    '$location',
    'mtsService',
    'plotToSignalflowV2',
    'chartUtils',
    'routeParameterService',
    'PRODUCT_NAME',
    'DetectorV2SearchService',
    'migratedSignalboost',
    'blockConversionFunctions',
    'SIGNALFLOW_CONSTANTS',
    'CHART_DISPLAY_EVENTS',
    function (
        $timeout,
        $sanitize,
        chartbuilderUtil,
        analyticsService,
        signalboostUtil,
        $q,
        $log,
        plotUtils,
        regExStyles,
        programTextUtils,
        sourceFilterService,
        rollupsFromMetricType,
        colorAccessibilityService,
        plotSignalSuggestionService,
        userAnalytics,
        PLOT_OPTION_STRINGS,
        detectorService,
        urlOverridesService,
        detectorSettingsModal,
        $location,
        mtsService,
        plotToSignalflowV2,
        chartUtils,
        routeParameterService,
        PRODUCT_NAME,
        DetectorV2SearchService,
        migratedSignalboost,
        blockConversionFunctions,
        SIGNALFLOW_CONSTANTS,
        CHART_DISPLAY_EVENTS
    ) {
        return {
            restrict: 'E',
            scope: {
                allPlots: '=',
                plot: '=',
                passedUtils: '=',
                editMode: '=',
                isDetectorPlot: '=',
                chartMode: '=',
                calendarWindowTimezone: '=',
                sourceListUpdated: '=?', //callback to inform parent that the results of the source query are ready
                variables: '=?',
                sourceOverrides: '=?',
                validateDelete: '=?',
                disableBrowse: '=?',
                selectedSignal: '=?',
                isModal: '=?',
                compoundConditionAvailable: '<?',
                compoundSelected: '<?',
                valueLabel: '<',
                latitudeLabel: '<',
                longitudeLabel: '<',
                getCurrentTimeRange: '<?',
            },
            templateUrl,
            link: function ($scope, elem) {
                const HISTOGRAM_METRIC_MODAL_DEFAULT_STATE = {
                    init: false,
                    isModalOpen: false,
                    isTransformationApplied: false,
                    appliedFunction: '',
                };
                const PERCENTILE_DEFAULT_BLOCK = {
                    args: { pct: { value: DEFAULT_PERCENTILE_VALUE } },
                };

                let currentSignalSuggestPromise;
                let onMetricSuggestBlurDeferredSelection;
                $scope.PRODUCT_NAME = PRODUCT_NAME;
                $scope.metricFilterItemTemplateUrl = metricFilterItemTemplateUrl;
                $scope.formulateTooltipTemplateUrl = formulateTooltipTemplateUrl;
                $scope.selectSignalMode = angular.isDefined($scope.selectedSignal);
                $scope.accessibleColors = colorAccessibilityService.get().getPlotColors();
                $scope.originalElement = elem;
                $scope.sourceListUpdated = $scope.sourceListUpdated || angular.noop;
                $scope.plotExpressionKey = chartbuilderUtil.getLetterFromUniqueKey(
                    $scope.plot.uniqueKey
                );
                $scope.showSignalButton = $scope.plot.transient;
                $scope.hasLabel = $scope.plot.name && $scope.plot.name.trim().length;
                $scope.altMode = false;
                $scope.plot.configuration = $scope.plot.configuration || {};
                $scope.PLOT_OPTION_STRINGS = PLOT_OPTION_STRINGS;
                $scope.METRIC_TYPE = SIGNALFLOW_CONSTANTS.METRIC_TYPE;
                $scope.histogramMetricModal = HISTOGRAM_METRIC_MODAL_DEFAULT_STATE;
                $scope.HISTOGRAM_FUNCTIONS = {
                    MAX: 'Max',
                    MIN: 'Min',
                    COUNT: 'Count',
                    MEAN: 'Mean',
                    SUM: 'Sum',
                    PERCENTILE: 'Percentile',
                };
                $scope.archivedMetricName = null;

                $scope.$on(CHART_DISPLAY_EVENTS.ARCHIVED_METRICS_FOUND, function (event, metrics) {
                    if (metrics[$scope.plot.name]) {
                        $scope.archivedMetricName = $scope.plot.name;
                    }
                });

                const TRANSFORMATION_DEFAULT_BLOCK = {
                    args: {
                        over: {
                            type: 'string',
                            value: '1h',
                        },
                    },
                };

                const MULTIPLE_METRIC_TYPE = 'MULTIPLE';

                $scope.$on('$destroy', onDestroy);

                let startTime;
                let endTime;

                const VALID_POLICIES = {
                    NULL_EXTRAPOLATION: 'NULL_EXTRAPOLATION',
                    ZERO_EXTRAPOLATION: 'ZERO_EXTRAPOLATION',
                    LAST_VALUE_EXTRAPOLATION: 'LAST_VALUE_EXTRAPOLATION',
                };

                // Optimization to reduce calls to backend to dereference detector ids
                const detectorIdToNameCache = {};

                function getDetectorNameForId(detectorId) {
                    const cached = detectorIdToNameCache[detectorId];
                    if (cached) {
                        return cached;
                    }

                    const toCache = detectorService.get(detectorId).then((detector) => {
                        // This could be a v1 or v2 detector
                        return detector.sf_detector || detector.name;
                    });

                    detectorIdToNameCache[detectorId] = toCache;
                    return toCache;
                }

                $scope.applyUnitChanges = function (unitType, prefix, suffix) {
                    if (unitType) {
                        $scope.plot.configuration.unitType = unitType;
                        $scope.plot.configuration.prefix = '';
                        $scope.plot.configuration.suffix = '';
                    } else if (prefix || suffix) {
                        $scope.plot.configuration.unitType = null;
                        $scope.plot.configuration.prefix = prefix;
                        $scope.plot.configuration.suffix = suffix;
                    } else {
                        $scope.plot.configuration.unitType = null;
                        $scope.plot.configuration.prefix = '';
                        $scope.plot.configuration.suffix = '';
                    }
                };
                $scope.handleKeydown = function (event) {
                    if (event.key === 'Enter') {
                        const unitsPickerElement = angular.element(
                            document.querySelector('units-picker')
                        );
                        const unitsPickerScope =
                            unitsPickerElement.isolateScope() || unitsPickerElement.scope();
                        if (unitsPickerScope) {
                            unitsPickerScope.$ctrl.toggleDropdown();
                        }
                    }
                };

                function highlight() {
                    const HIGHLIGHT_DURATION_MS = 10 * 1000;
                    $scope.highlightPlot = true;
                    $timeout(() => {
                        $scope.highlightPlot = false;
                    }, HIGHLIGHT_DURATION_MS);
                }

                function highlightAndPrefillLinkDetectorField(detectorIdToLinkTo) {
                    $scope.showDetectorInput();
                    highlight();

                    if (detectorIdToLinkTo) {
                        linkDetectorById(detectorIdToLinkTo);
                    }
                }

                function plotWatcherCallback() {
                    //if the plot object is replaced(transient plot overwrite via clone), then update validation gate state values
                    //do not make this a deep compare.

                    const plot = $scope.plot;
                    const seriesData = plot.seriesData;

                    if (plot.type === 'plot') {
                        if (plot.seriesData.metric) {
                            $scope.tentativeMetricSuggest = seriesData.metric;
                        }
                    } else if (plot.type === 'ratio') {
                        $scope.tempExpressionText = plot.expressionText;
                    } else if (plot.type === 'event') {
                        if (seriesData.detectorId) {
                            getDetectorNameForId(seriesData.detectorId).then((name) => {
                                $scope.tempEventSuggest = name;
                                if (!plot.name) {
                                    setPlotName(name);
                                }
                            });
                        } else if (seriesData.autodetectId) {
                            $scope.tempEventSuggest = 'AutoDetect';
                        } else {
                            $scope.tempEventSuggest =
                                seriesData.eventQuery || seriesData.detectorQuery;
                        }
                    }
                    $scope.updateAvailableMetricTypes();
                    setObjectType();
                }

                $scope.$watchGroup(['plot.seriesData.metric', 'plot'], () => plotWatcherCallback());

                function enforceHeatmapSingleVisiblePlot() {
                    // For heatmap plots, Make only this plot visible
                    if ($scope.chartMode === 'heatmap') {
                        plotUtils.updatePlotVisibility(
                            $scope.chartMode,
                            $scope.allPlots,
                            $scope.plot
                        );
                    }
                }

                $scope.$on('update plot expression key', function () {
                    $scope.plotExpressionKey = chartbuilderUtil.getLetterFromUniqueKey(
                        $scope.plot.uniqueKey
                    );
                    $scope.tempExpressionText = $scope.plot.expressionText;
                });

                function setObjectType() {
                    $scope.objectType = [
                        $scope.plot.type === 'event' ? 'EventTimeSeries' : 'MetricTimeSeries',
                    ];
                }

                $scope.setPlotType = function (type) {
                    if ($scope.plot.transient === true) {
                        $scope.plot.type = type;
                    }
                    setObjectType();
                };

                // Toggle between buttons to add formula/signal and signal input
                let signalInput;
                $scope.showSignalInput = function (ignoreBlur) {
                    $scope.showSignalButton = false;
                    signalInput = elem.find('.metric-selection-input');
                    signalInput.addClass('narrow').removeClass('sf-invisible').focus();
                    if (!ignoreBlur) {
                        signalInput.on('blur.signal', function () {
                            // Unless suggestions list is open
                            if (signalInput.siblings('.dropdown-menu').find('li').length > 0) {
                                return;
                            }
                            signalInput.addClass('sf-invisible').removeClass('narrow');
                            $scope.showSignalButton = true;
                            signalInput.off('blur.signal');
                            $scope.$digest();
                        });
                    }
                };

                let detectorInput;
                $scope.showDetectorInput = function (ignoreBlur) {
                    $scope.showSignalButton = false;
                    // Ensure the metric selection input goes invisible incase it was
                    // visible (like when a new chart is being created, it's visible by
                    // default).
                    elem.find('.metric-selection-input').addClass('sf-invisible');
                    detectorInput = elem.find('.detector-selection-input');
                    detectorInput.addClass('narrow').removeClass('sf-invisible').focus();
                    $scope.disableBrowse = true;
                    if (!ignoreBlur) {
                        detectorInput.on('blur.signal', function () {
                            // Unless suggestions list is open
                            if (detectorInput.siblings('.dropdown-menu').find('li').length > 0) {
                                return;
                            }
                            detectorInput.addClass('sf-invisible').removeClass('narrow');
                            $scope.showSignalButton = true;
                            $scope.disableBrowse = false;
                            detectorInput.off('blur.signal');
                            $scope.$digest();
                        });
                    }
                };

                // Highlights a detector plot if the highlightDetectorPlot search param is found
                function highlightDetectorPlotFromSearchParams() {
                    if (!urlOverridesService.hasHighlightDetectorPlot()) {
                        return;
                    }

                    const highlightedDetectorId = urlOverridesService.getHighlightDetectorPlot();
                    const plot = $scope.plot;

                    const isMatchingDetectorPlot =
                        plot.seriesData &&
                        plot.seriesData.detectorId &&
                        plot.seriesData.detectorId === highlightedDetectorId;
                    if (!isMatchingDetectorPlot) {
                        return;
                    }

                    // Consume the highlightDetectorPlot request
                    urlOverridesService.clearHighlightDetectorPlot();
                    urlOverridesService.useReplaceForLastChange();
                    highlight();
                }

                $scope.$on('link detector', ($event, detectorId) => {
                    if (!$scope.plot.transient) {
                        return;
                    }

                    highlightAndPrefillLinkDetectorField(detectorId);
                });

                // Prefills a link detector plot if the linkDetector search param is found
                function prefillLinkDetectorFromSearchParams() {
                    const plot = $scope.plot;
                    if (!plot.transient || !urlOverridesService.hasLinkDetector()) {
                        return;
                    }

                    const detectorIdToLinkTo = urlOverridesService.getLinkDetector();
                    urlOverridesService.clearLinkDetector();
                    urlOverridesService.useReplaceForLastChange();

                    // Ensure the new detector field is enabled once angular linking is done.
                    $timeout(() => {
                        highlightAndPrefillLinkDetectorField(detectorIdToLinkTo);
                    });
                }

                function initFromSearchParams() {
                    highlightDetectorPlotFromSearchParams();
                    prefillLinkDetectorFromSearchParams();
                }

                initFromSearchParams();

                // Toggle between buttons to add formula/signal and formula input
                let formulaInput;
                $scope.showFormulaInput = function () {
                    $scope.plot.type = 'ratio';
                    $scope.showSignalButton = false;
                    formulaInput = elem.find('.formula-selection-input');
                    formulaInput
                        .removeClass('sf-invisible')
                        .focus()
                        .on('blur.formula', function () {
                            // Unless a value has been entered
                            if (formulaInput.val()) {
                                return;
                            }
                            formulaInput.addClass('sf-invisible');
                            $scope.showSignalButton = true;
                            $scope.plot.type = 'plot';
                            formulaInput.off('blur.formula');
                            $scope.$digest();
                        });
                };

                function getDefaultResult() {
                    // default result attempts to find the selection option most suitable to the context provided
                    // for transient plots, this means a metric(since we dont really know what the user wants)
                    // for regular plots, a metric while maintaining the regExStyle if any
                    // for events, either a detector name or an event query, as appropriate
                    if ($scope.plot.transient || $scope.plot.type === 'plot') {
                        const result = {
                            type: 'metric',
                            value: $scope.tentativeMetricSuggest,
                            isSynthetic: true,
                        };

                        if ($scope.plot.seriesData.regExStyle) {
                            result.regExStyle =
                                $scope.plot.seriesData.regExStyle === 'plain'
                                    ? null
                                    : $scope.plot.seriesData.regExStyle;
                        }

                        return result;
                    } else if ($scope.plot.type === 'event') {
                        const result = {
                            value: $scope.tempEventSuggest,
                            isSynthetic: true,
                        };

                        if ($scope.plot.seriesData.eventQuery) {
                            result.type = 'eventTimeSeries';
                            return result;
                        } else if ($scope.plot.seriesData.detectorQuery) {
                            result.type = 'detectorEvents';
                            return result;
                        }
                    }
                    return null;
                }

                $scope.getSignalSuggestionsDebounced = function (q, transient) {
                    // we need to control debouncing outside of ng-model and typeahead directives
                    // in order to accurately populate the "is fetching" behavior
                    $timeout.cancel(currentSignalSuggestPromise);
                    currentSignalSuggestPromise = $timeout(function () {
                        const resultPromise = plotSignalSuggestionService.getSignalSuggestions(
                            q,
                            transient,
                            $scope.allPlots,
                            $scope.plot,
                            $scope.chartMode
                        );

                        return resultPromise.then(function (rs) {
                            const noExactQueryMatch = !rs.some(function (resp) {
                                return resp.value === q;
                            });

                            if (noExactQueryMatch && q) {
                                const defaultResult = getDefaultResult();
                                if (defaultResult) {
                                    rs.push(defaultResult);
                                }
                            }
                            return rs;
                        });
                    }, 200);
                    return currentSignalSuggestPromise;
                };

                $scope.getDetectorSuggestionsDebounced = function (q) {
                    // we need to control debouncing outside of ng-model and typeahead directives
                    // in order to accurately populate the "is fetching" behavior
                    $timeout.cancel(currentSignalSuggestPromise);
                    currentSignalSuggestPromise = $timeout(function () {
                        const query = q.toLowerCase().replace(/\s/g, '*');

                        return DetectorV2SearchService.search({
                            name: query,
                        }).then((resp) => {
                            if (!resp || !resp.rs) return [];

                            return resp.rs.map((detector) => {
                                return {
                                    type: 'detector',
                                    value: detector.sf_detector,
                                    detectorId: detector.sf_id,
                                };
                            });
                        });
                    }, 200);
                    return currentSignalSuggestPromise;
                };

                function sanitizeMetric(m, plot) {
                    if (plot.seriesData && plot.seriesData.regExStyle === 'graphite') {
                        //this wont work for anything analytics is going to manipulate
                        return 'sf_metric:' + sanitizeTermForGraphite(m);
                    } else if (plot.seriesData && plot.seriesData.regExStyle === 'newrelic') {
                        //this wont work for anything analytics is going to manipulate
                        return 'sf_metric:' + sanitizeTermForNewRelic(m);
                    } else {
                        const rewritten = filterReWritable('sf_metric', m, false);
                        if (rewritten) {
                            return rewritten;
                        } else {
                            return 'sf_metric:' + sanitizeTerm(m, true);
                        }
                    }
                }

                $scope.getCurrentQuery = function () {
                    const rootPlot = $scope.plot;
                    const relevantUKs = plotUtils.getAllRelevantPlots(
                        rootPlot,
                        {},
                        $scope.allPlots
                    );

                    if (rootPlot.type === 'event') {
                        return analyticsService.getEventQuery([], rootPlot.seriesData);
                    }

                    const relevantPlots = $scope.allPlots.filter(function (plot) {
                        return relevantUKs[plot.uniqueKey] === true;
                    });

                    const finalPrograms = [];
                    angular.forEach(relevantPlots, function (plot) {
                        if (plot.type === 'plot') {
                            finalPrograms.push({
                                programText: plotToSignalflowV2.generate(plot, 'AUTOSUGGEST'),
                                packageSpecifications: '',
                            });
                        }
                    });

                    return finalPrograms;
                };

                $scope.expressionKeyPressed = function (ev) {
                    if (ev.keyCode === 13) {
                        $scope.applyExpressionValue($scope.tempExpressionText);
                    }
                };

                $scope.updateExpressionValidation = function (expressionText, plots, uniqueKey) {
                    if ($scope.plot.type !== 'ratio') {
                        return;
                    }
                    if (expressionText) {
                        const invalid = programTextUtils.isExpressionInvalid(
                            expressionText,
                            plots,
                            uniqueKey
                        );
                        if (expressionText && invalid) {
                            $scope.plot.valid = false;
                            $scope.expressionValidationErrors = invalid;
                        } else {
                            $scope.plot.valid = true;
                            $scope.expressionValidationErrors = null;
                        }
                    } else {
                        $scope.plot.valid = false;
                        $scope.expressionValidationErrors = null;
                    }
                };

                $scope.$watch('tempExpressionText', function (n) {
                    if (n && n !== n.toUpperCase()) {
                        $scope.tempExpressionText = n.toUpperCase();
                        return;
                    }
                    $scope.updateExpressionValidation(n, $scope.allPlots, $scope.plot.uniqueKey);
                });

                $scope.$watchCollection('plot.dataManipulations', function () {
                    $scope.updateSourceList();
                });

                $scope.displayHistogramFunction = '';

                function getSelectedHistogramFunctionDisplayName(selectedFunctions) {
                    /**
                     * @callback analyticsService.createDataManipulationName takes the direction object
                     * as an argument and returns the display text which is currently used by the analytics function
                     * to display text in the UI
                     *
                     * @name histogram wll use the same function to get the UI text of the aggregation and transformation.
                     * Then it'll combine both the text to get the desired UI display text for the histogram metric
                     */
                    const directionTypesDisplayName = selectedFunctions.map((selectedFunction) => ({
                        value: analyticsService.createDataManipulationName(selectedFunction),
                        directionType: selectedFunction.direction.type,
                    }));
                    return createHistogramManipulationName(directionTypesDisplayName);
                }

                function renderHistogramFunction() {
                    $scope.displayHistogramFunction = getSelectedHistogramFunctionDisplayName(
                        $scope.plot.histogramMethods
                    );
                }

                /**
                 * Add the default transformation object which is used when user toogle the transformation window
                 */
                function setDefaultTransformationBlock(fnName) {
                    if ($scope.plot.histogramMethods.length < 2) {
                        const blockFunction = blockConversionFunctions[fnName.toLowerCase()];
                        if (blockFunction) {
                            $scope.plot.histogramMethods[1] = blockFunction(
                                TRANSFORMATION_DEFAULT_BLOCK,
                                $scope.METRIC_TYPE.HISTOGRAM
                            );
                        }
                    }
                }

                function removeTransformationBlock() {
                    if ($scope.plot.histogramMethods?.length === 2) {
                        $scope.plot.histogramMethods.pop();
                    }
                }

                $scope.handleHistogramFunctions = (appliedFunction) => {
                    // skip if selected function is the same
                    if (appliedFunction === $scope.histogramMetricModal.appliedFunction) {
                        return;
                    }

                    const isPercentileFn =
                        appliedFunction === $scope.HISTOGRAM_FUNCTIONS.PERCENTILE.toUpperCase();

                    $scope.histogramMetricModal = {
                        ...$scope.histogramMetricModal,
                        appliedFunction,
                    };

                    if (!$scope.histogramMetricModal.init) {
                        // if histogram metric modal is already initialized we need to only change applied function
                        $scope.plot.histogramMethods?.forEach((histogramMethod) => {
                            histogramMethod.fn.type = appliedFunction;

                            if (isPercentileFn) {
                                histogramMethod.fn.options = {
                                    ...(histogramMethod.fn.options || {}),
                                    percentile: DEFAULT_PERCENTILE_VALUE,
                                };
                            }

                            if (histogramMethod.fn.options.windowType) {
                                histogramMethod.fn.options.windowType =
                                    CALENDAR_WINDOW_CONSTANTS.ANALYTICS_WINDOW_TYPES.MOVING_WINDOW;
                            }

                            return histogramMethod;
                        });

                        return;
                    }

                    const blockConversionFn =
                        blockConversionFunctions[appliedFunction.toLowerCase()];

                    const defaultBlockOptions = isPercentileFn ? PERCENTILE_DEFAULT_BLOCK : {};

                    const histogramMethod = blockConversionFn(
                        defaultBlockOptions,
                        $scope.METRIC_TYPE.HISTOGRAM
                    );

                    $scope.histogramMetricModal = {
                        ...$scope.histogramMetricModal,
                        init: false,
                    };

                    Object.assign($scope.plot, {
                        invisible: false,
                        incomplete: false,
                        init: false,
                        histogramMethods: [histogramMethod],
                    });

                    userAnalytics.event(
                        'histogram-metric',
                        'histogram-function-changed',
                        appliedFunction
                    );
                };

                /**
                 * Add the default transformation object when user toggle to aggrgation-mode only
                 */
                $scope.$watch(
                    'histogramMetricModal.isTransformationApplied',
                    (isTransformationApplied) => {
                        if ($scope.histogramMetricModal.isModalOpen) {
                            if (isTransformationApplied) {
                                setDefaultTransformationBlock(
                                    $scope.histogramMetricModal.appliedFunction
                                );
                            } else {
                                removeTransformationBlock();
                            }
                        }
                    }
                );

                function mergeAggregationOptionsToTransformation() {
                    if ($scope.histogramMetricModal.isTransformationApplied) {
                        merge(
                            $scope.plot.histogramMethods[1].fn,
                            $scope.plot.histogramMethods[0].fn
                        );
                    }
                }

                /**
                 * plot.histogramMethods[0] is aggragation-method
                 * plot.histogramMethods[1] is transformation-method
                 */
                $scope.$watch('plot.histogramMethods', () => {
                    const isHistogramMethodsApplied = $scope.plot.histogramMethods?.length;
                    if (isHistogramMethodsApplied) {
                        renderHistogramFunction();
                        $scope.histogramMetricModal = {
                            ...$scope.histogramMetricModal,
                            isTransformationApplied: !!$scope.plot.histogramMethods[1],
                            appliedFunction: $scope.plot.histogramMethods[0].fn.type,
                        };
                    }
                });

                $scope.$watch('histogramMetricModal.isModalOpen', (isModalOpen) => {
                    if ($scope.plot.histogramMethods?.length && !isModalOpen) {
                        mergeAggregationOptionsToTransformation();
                        renderHistogramFunction();
                    }
                });

                $scope.applyExpressionValue = function (v) {
                    $scope.plot.valid = !programTextUtils.isExpressionInvalid(
                        v,
                        $scope.allPlots,
                        $scope.plot.uniqueKey
                    );

                    if ($scope.plot.valid) {
                        // Once a formula has been selected, stop toggling between the
                        // button and input
                        if (formulaInput) {
                            formulaInput.off('blur.formula');
                        }
                        $scope.plot.expressionText = v;
                        $scope.sourceListUpdated(null, $scope.plot.uniqueKey);
                    }
                    enforceHeatmapSingleVisiblePlot();
                };

                $scope.$watchCollection('plot.queryItems', function (newFilters) {
                    const filterButton = elem.find('.plot-filter-button');
                    if (newFilters && newFilters.length) {
                        filterButton.text('+');
                    } else {
                        filterButton.text('Add filter');
                    }

                    $scope.updateAvailableMetricTypes();
                });

                const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                    ['sources[]', 'variables[]'],
                    function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            $scope.updateAvailableMetricTypes();
                        }
                    }
                );

                $scope.$watch('plot.configuration.colorOverride', function (newVal) {
                    $scope.accessibleColorOverride = colorAccessibilityService
                        .get()
                        .convertPlotColorToAccessible(newVal);
                });

                $scope.updatePropertyList = function () {
                    const props = $scope.propertyFilterList || [];
                    const keys = $scope.dimensionKeys || [];
                    let result = [];

                    if (props.length || keys.length) {
                        result = result.concat(
                            keys.map(function (key) {
                                return {
                                    icon: 'icon-dimensions',
                                    value: key,
                                };
                            })
                        );
                        props.forEach(function (prop) {
                            if (
                                keys.indexOf(prop) === -1 &&
                                prop.charAt(0) !== '_' &&
                                (prop.indexOf('sf_') !== 0 ||
                                    prop === 'sf_source' ||
                                    prop === 'sf_metric')
                            ) {
                                result.push({
                                    icon: 'icon-properties',
                                    value: prop,
                                });
                            }
                        });
                    }

                    $scope.propertySuggestions = result;
                };

                $scope.updateSourceList = function () {
                    if ($scope.plot && $scope.plot.dataManipulations.length === 0) {
                        $scope.sourceListUpdated(
                            $scope.latestSourceList || null,
                            $scope.plot.uniqueKey
                        );
                    } else {
                        $scope.sourceListUpdated(null, $scope.plot.uniqueKey);
                    }
                };

                $scope.onMetricSuggestBlur = function () {
                    if (!$scope.plot.transient) {
                        const defaultResult = getDefaultResult();
                        if (defaultResult) {
                            // this fixes a bug wherein this handler can overwrite the user selected value due
                            // to two callbacks attempting to modify the same field, which causes an oscillation
                            // of values when clicking a result based on a partially typed value

                            // ng-=blur will occur before any selection call can proceed, so in order to
                            //preserve the behavior wherein we "accept" the typed value upon the user clicking
                            //out, we have to wait to see if an actual signal is chosen before applying the
                            //typed value(in this case the default).
                            onMetricSuggestBlurDeferredSelection = $timeout(function () {
                                $scope.onSignalSelected(defaultResult);
                            }, 100);
                        }
                    }
                };

                $scope.onMetricPickerKeyPress = function (e) {
                    // if a submission occurs, make our best guess as to user intent
                    // for transient plot, this means a metric
                    // for non transient plots, this means adjusting the query string
                    // for the specific type of plot it is
                    if (e.keyCode === 13 || e.keyCode === 9) {
                        if ($scope.plot.transient) {
                            if ($scope.tentativeMetricSuggest) {
                                $scope.onSignalSelected({
                                    type: 'metric',
                                    value: $scope.tentativeMetricSuggest,
                                    isSynthetic: true,
                                });
                            }
                        } else {
                            const defaultResult = getDefaultResult();
                            if (defaultResult) {
                                $scope.onSignalSelected(defaultResult);
                            }
                        }
                        e.stopPropagation();
                    }
                };

                $scope.onSignalSelected = function (item) {
                    // Once a signal has been selected, stop toggling between the
                    // button and input
                    if (item.isArchived) {
                        $scope.archivedMetricName = item.value;
                    }
                    $timeout.cancel(onMetricSuggestBlurDeferredSelection);
                    if (signalInput) {
                        signalInput.off('blur.signal');
                    }

                    // if selection occurs while we are loading results, accept what the user types directly by overwriting
                    // the selection object being passed
                    if (
                        $scope.isLoadingMetrics ||
                        $scope.isLoadingEvents ||
                        ($scope.noMetricResults && $scope.noEventResults)
                    ) {
                        const defaultResult = getDefaultResult();
                        if (defaultResult) {
                            item = defaultResult;
                        }
                    }

                    if (item.type === 'metric') {
                        $scope.setPlotType('plot');
                        $scope.tentativeMetricSuggest = item.value;
                        $scope.onMetricSelected(item.value, item.regExStyle || null);

                        // Remove placeholder, since its input cannot be blank now and
                        // the input might shrink such that the placeholder would not fit
                        // in the width anymore
                        if (signalInput) {
                            signalInput.removeAttr('placeholder').removeClass('narrow');
                        }
                    } else if (item.type === 'detectorEvents') {
                        $scope.setPlotType('event');
                        $scope.tempEventSuggest = item.value;
                        $scope.onEventSelected(item.value, 'detector');
                    } else if (item.type === 'eventTimeSeries') {
                        $scope.setPlotType('event');
                        $scope.tempEventSuggest = item.value;
                        $scope.onEventSelected(item.value, 'ets');
                    } else {
                        $log.error('An input of neither metric or expression type was submitted.');
                    }
                    enforceHeatmapSingleVisiblePlot();
                };

                function linkDetectorById(detectorId) {
                    if (detectorId === 'new') return;

                    getDetectorNameForId(detectorId).then((detectorName) => {
                        $scope.setPlotType('event');
                        $scope.tempEventSuggest = detectorName;
                        delete $scope.plot.seriesData.eventQuery;
                        delete $scope.plot.seriesData.detectorQuery;
                        $scope.plot.seriesData.detectorId = detectorId;
                        $scope.plot.name = detectorName;
                    });
                }

                $scope.onDetectorSelected = function (item) {
                    // Once a signal has been selected, stop toggling between the
                    // button and input
                    if (detectorInput) {
                        detectorInput.off('blur.signal');
                    }

                    // if selection occurs while we are loading results, accept what the user types directly by overwriting
                    // the selection object being passed
                    if (
                        $scope.isLoadingMetrics ||
                        $scope.isLoadingEvents ||
                        ($scope.noMetricResults && $scope.noEventResults)
                    ) {
                        const defaultResult = getDefaultResult();
                        if (defaultResult) {
                            item = defaultResult;
                        }
                    }

                    $scope.setPlotType('event');
                    $scope.tempEventSuggest = item.value;
                    delete $scope.plot.seriesData.eventQuery;
                    delete $scope.plot.seriesData.detectorQuery;
                    $scope.plot.seriesData.detectorId = item.detectorId;
                    $scope.plot.name = item.value;
                };

                $scope.validateRegEx = function () {
                    if ($scope.plot.seriesData.regExStyle) {
                        return plotUtils.validateRegEx(
                            $scope.plot.seriesData.metric,
                            $scope.plot.seriesData.regExStyle
                        );
                    } else {
                        return null;
                    }
                };

                function getAliasInfo() {
                    $scope.canAlias = plotUtils.isAliasedRegExStyle($scope.plot);
                    $scope.aliasSamples = [];
                    const aliases = {};

                    if ($scope.canAlias) {
                        if (!$scope.isDetectorPlot) {
                            plotUtils.getNodeSamplesForAlias($scope.plot).then(function (samples) {
                                $scope.aliasSamples = samples || [];
                            });
                        }
                        const delim = regExStyles.getDelimiter($scope.plot.seriesData.regExStyle);
                        $scope.plot.seriesData.metric.split(delim).forEach(function (str, idx) {
                            aliases[idx] =
                                ($scope.plot.configuration.aliases &&
                                    $scope.plot.configuration.aliases[idx]) ||
                                null;
                        });
                    }
                    $scope.plot.configuration.aliases = aliases;
                }

                getAliasInfo();

                $scope.onMetricSelected = function (metric, regExStyle) {
                    $scope.plot.seriesData.metric = metric;
                    $scope.updateAvailableMetricTypes(true).then(
                        function (res) {
                            const rollups = rollupsFromMetricType.get(res);
                            if (
                                !$scope.plot.configuration ||
                                !rollups ||
                                !rollups.some(function (policy) {
                                    return policy.value === $scope.plot.configuration.rollupPolicy;
                                })
                            ) {
                                $scope.setRollup(null);
                            }
                            //should we not store regExStyle plain since its no different than plain metric for escaping purposes?
                            $scope.regExErrors = [];
                            if (regExStyle && regExStyle !== 'plain') {
                                $scope.plot.seriesData.regExStyle = regExStyle;
                                $scope.regExErrors = $scope.validateRegEx();
                            } else {
                                //clear on regex style change?  who knows...
                                //$scope.plot.configuration.aliases = {};
                                $scope.plot.seriesData.regExStyle = null;
                            }
                            getAliasInfo();

                            $timeout(function () {
                                angular
                                    .element('.source-selection-input', $scope.originalElement)
                                    .focus();
                            }, 0);
                        },
                        function () {
                            $scope.setRollup(null);
                            //should we not store regExStyle plain since its no different than plain metric for escaping purposes?
                            $scope.plot.seriesData.regExStyle = regExStyle;
                            getAliasInfo();

                            $timeout(function () {
                                angular
                                    .element('.source-selection-input', $scope.originalElement)
                                    .focus();
                            }, 0);
                        }
                    );
                };

                $scope.onEventSelected = function (ets, type) {
                    if (type === 'ets') {
                        delete $scope.plot.seriesData.detectorQuery;
                        $scope.plot.seriesData.eventQuery = ets;
                    } else if (type === 'detector') {
                        delete $scope.plot.seriesData.eventQuery;
                        $scope.plot.seriesData.detectorQuery = ets;
                    }
                };

                $scope.refocusMetricInput = function () {
                    $timeout(function () {
                        angular
                            .element('.metric-selection-input', $scope.originalElement)
                            .first()
                            .focus();
                    }, 0);
                };

                $scope.refocusExpressionInput = function () {
                    $timeout(function () {
                        angular.element('.formula-selection-input', $scope.originalElement).focus();
                    }, 0);
                };

                $scope.refocusAnalyticsInput = function (ev) {
                    if (ev) {
                        ev.stopPropagation();
                    }
                    $timeout(function () {
                        angular
                            .element('.analytics-selection-input', $scope.originalElement)
                            .first()
                            .focus();
                    }, 0);
                };

                $scope.$on('refocus-analytics', $scope.refocusAnalyticsInput);

                $scope.setRollup = function (policy) {
                    $scope.plot.configuration.rollupPolicy = policy ? policy.value : null;
                    $scope.rollupDisplayName = rollupsFromMetricType.getCurrentRollupDisplayName(
                        $scope.knownMetricTypes,
                        $scope.plot.configuration.rollupPolicy
                    );
                    $scope.setRollupColumn();
                };

                $scope.setRollupColumn = function () {
                    if ($scope.matchingSourceSize === 0) {
                        // Rollup information is known to be unavailable
                        $scope.rollupDisplayColumn = '';
                    } else if (angular.isUndefined($scope.rollupApplied)) {
                        if ($scope.plot.invisible || $scope.plot.transient) {
                            // Invisible plots will only be included in the analytics job if used in a visible formula plot
                            $scope.rollupDisplayColumn = ''; // Rollup information is known to be unavailable
                        } else {
                            $scope.rollupDisplayColumn = 'Determining...';
                        }
                    } else {
                        $scope.rollupDisplayColumn = `${$scope.rollupDisplayName} rollup`;
                    }
                };

                $scope.forceSourceUpdate = function (key) {
                    $scope.$broadcast('setViewValue', 'sourceSuggest', key + ':', true);
                };

                function setRollupAndResolutionInfo(plotData) {
                    if (!plotData) {
                        return;
                    }

                    $scope.rollupApplied = plotData.rollupApplied;
                    $scope.setRollupColumn();

                    if (angular.isUndefined($scope.rollupApplied)) {
                        if ($scope.plot.invisible || $scope.plot.transient) {
                            // Invisible plots will only be included in the analytics job if used in a visible formula plot
                            $scope.rollupTooltipMessage = ''; // Rollup information is known to be unavailable
                        } else {
                            $scope.rollupTooltipMessage = 'Determining rollup...';
                        }
                        return;
                    }

                    const resolution = convertMSToString(plotData.resolution);
                    $scope.rollupTooltipMessage = $scope.rollupDisplayName + ' rollup';
                    if ($scope.rollupApplied) {
                        $scope.rollupTooltipMessage += ' applied to source data';
                        if (resolution) {
                            $scope.rollupTooltipMessage +=
                                ' to achieve ' +
                                resolution +
                                ($scope.isDetectorPlot ? ' detector' : '') +
                                ' resolution';
                        }
                    } else {
                        $scope.rollupTooltipMessage += ' not applied';
                        if (resolution) {
                            $scope.rollupTooltipMessage +=
                                '<br> (data sent in at ' + resolution + ' resolution)';
                        }
                    }
                }

                $scope.$on('plotTimeSeriesData', function (evt, plotKeyToInfoMap) {
                    const plotData = plotKeyToInfoMap[$scope.plot.uniqueKey];
                    if (plotData && plotData.tsidCount) {
                        const tsidData = plotData.tsidCount;
                        const dimKeys = {};
                        if (tsidData) {
                            $scope.matchingSourceSize = tsidData.numInputTimeSeries;
                            $scope.matchingMTSIds = tsidData.tsIds || null;
                            $scope.timeSeriesOmitted = (plotData.timeSeriesPrePublish || 100) - 100;
                        } else if ($scope.plot.type === 'ratio') {
                            $scope.correlationError = plotUtils.getCorrelationError(
                                $scope.plot,
                                $scope.allPlots,
                                plotKeyToInfoMap
                            );

                            if (
                                $scope.correlationError &&
                                $scope.correlationError.type ===
                                    plotUtils.CORRELATION_ERR_TYPES.BAD_AGGREGATION_GROUPINGS
                            ) {
                                $scope.$emit('aggErrorDisableRefresh', $scope.plot.uniqueKey);
                            } else if (!$scope.correlationError) {
                                $scope.$emit('aggErrorCleared', $scope.plot.uniqueKey);
                            }
                        }

                        $scope.cappedPlotKeys = plotData.cappedPlotKeys;

                        let dims = null;

                        if (plotData.dimensions) {
                            dims = plotData.dimensions
                                .map(function (dimDef) {
                                    return (
                                        dimDef.dimensions
                                            .filter(function (keyName) {
                                                dimKeys[keyName] = true;
                                                return keyName !== 'sf_metric';
                                            })
                                            .join(', ') +
                                        ' (' +
                                        dimDef.count +
                                        ')'
                                    );
                                })
                                .map(function (dimDef) {
                                    return $sanitize(dimDef);
                                });
                        }
                        $scope.matchingDimensions = dims;
                        $scope.dimensionKeys = Object.keys(dimKeys);
                        $scope.updateSourceList();
                        $scope.findCapped = plotData.mtsCap;
                    } else {
                        $scope.timeSeriesOmitted = -1;
                        $scope.matchingSourceSize = null;
                        $scope.matchingMTSIds = null;
                        $scope.matchingDimensions = null;
                        $scope.findCapped = false;
                    }

                    // After $scope has been updated
                    setRollupAndResolutionInfo(plotData);
                });

                $scope.showDetectorInfo = function (detectorId) {
                    return detectorService.get(detectorId).then(detectorSettingsModal.info);
                };

                $scope.openDetector = function (detectorId) {
                    $location.path('/detector/' + detectorId + '/edit');
                };

                $scope.updateAvailableMetricTypes = updateAvailableMetricTypes;

                function updateAvailableMetricTypes(omitMetricType) {
                    if (!$scope.plot.seriesData.metric) {
                        return false;
                    }

                    const timeRange = $scope.getCurrentTimeRange();
                    const start = timeRange.start;
                    const end = timeRange.end;
                    if (start !== startTime || end !== endTime) {
                        startTime = start;
                        endTime = end;

                        // Omit metric type and histogram methods to get proper results from /getMetricTypes endpoint
                        const pathsToOmit = ['histogramMethods'];
                        if (omitMetricType) {
                            pathsToOmit.push('metricType');
                        }
                        const plainPlot = omit($scope.plot, pathsToOmit);
                        const programText = plotToSignalflowV2.generate(plainPlot, 'AUTOSUGGEST');

                        const variables = $scope.variables || [];
                        const combinedFilters = chartUtils
                            .getChartOverrides(
                                variables.filter((f) => {
                                    return !f.replaceOnly;
                                })
                            )
                            .map(function (filt) {
                                return {
                                    property: filt.key,
                                    propertyValue: filt.value,
                                    applyIfExists: filt.applyIfExists,
                                    NOT: filt.not,
                                };
                            });

                        const replaceOnlyFilters = chartUtils
                            .getChartOverridesCustom(
                                variables.filter((f) => {
                                    return f.replaceOnly;
                                }),
                                []
                            )
                            .map(function (filt) {
                                return {
                                    property: filt.key,
                                    propertyValue: filt.value,
                                    applyIfExists: filt.applyIfExists,
                                    NOT: filt.not,
                                };
                            });

                        const filterStr =
                            sourceFilterService.translateSourceFilterObjectsToFilterBlock(
                                combinedFilters
                            );
                        const replaceOnlyFilterStr =
                            sourceFilterService.translateSourceFilterObjectsToFilterBlock(
                                replaceOnlyFilters
                            );
                        const programArgs = getProgramArgsForDashboardInTime({
                            absoluteStart: start,
                            absoluteEnd: end,
                        });

                        return mtsService
                            .getMetricTypes(
                                [programText],
                                start,
                                end,
                                filterStr,
                                replaceOnlyFilterStr,
                                programArgs
                            )
                            .then((data) => {
                                $scope.knownMetricTypes = data[0];
                                if ($scope.knownMetricTypes?.length > 1) {
                                    $scope.plot.metricType = MULTIPLE_METRIC_TYPE;
                                } else if ($scope.knownMetricTypes?.length === 1) {
                                    $scope.plot.metricType = $scope.knownMetricTypes[0].metricType;
                                }

                                $scope.setRollup({
                                    value: $scope.plot.configuration.rollupPolicy,
                                });

                                return $scope.knownMetricTypes;
                            });
                    }
                }

                $scope.$on('setGlobalTimeRanges', updateAvailableMetricTypes);

                $scope.updateCounterState = function () {
                    const metric = $scope.plot.seriesData.metric;
                    if (!metric) return;

                    const isWildCard = metric.indexOf('*') !== -1;

                    // use signalboost basicSearch at some point to get filtering
                    const query = sanitizeMetric(metric, $scope.plot);
                    migratedSignalboost.metric
                        .get('', {
                            query: signalboostUtil.inactiveEmitterFilter(query),
                        })
                        .then(
                            function (data) {
                                let matchingMetrics = [];
                                if (isWildCard) {
                                    matchingMetrics = data.rs;
                                } else {
                                    matchingMetrics = data.rs.filter(function (responseMetric) {
                                        return responseMetric.sf_metric === metric;
                                    });
                                }

                                if (matchingMetrics.length < 1) {
                                    $log.warn("Couldn't lookup the metric");
                                }

                                $scope.isCounter = matchingMetrics.some(function (metric) {
                                    return metric.sf_metricType === 'COUNTER';
                                });
                            },
                            function () {
                                $log.error(
                                    'Failed to lookup state of the metric being queried in a plot definition.'
                                );
                            }
                        );
                };

                $scope.$watch('plot.seriesData.metric', function () {
                    $scope.updateCounterState();
                    $scope.dimensionKeys = [];
                    $scope.propertyFilterList = [];
                });

                $scope.$watch('plot.metricType', function (newValue, oldValue) {
                    if (newValue && newValue !== oldValue) {
                        const isHistogramMetric =
                            $scope.plot.metricType === $scope.METRIC_TYPE.HISTOGRAM;

                        const update = {
                            histogramMethods: [],
                        };

                        if (isHistogramMetric) {
                            update.invisible = true;
                            update.incomplete = true;

                            $scope.histogramMetricModal = {
                                ...HISTOGRAM_METRIC_MODAL_DEFAULT_STATE,
                                init: true,
                                isModalOpen: true,
                            };
                        }

                        Object.assign($scope.plot, update);
                    }
                });

                function setPlotName(name) {
                    $scope.plot.name = name;
                    $scope.hasLabel = true;
                }

                $scope.$watch('plot.seriesData.eventQuery', function (val, old) {
                    if (val && val !== old && $scope.plot.type === 'event') {
                        $scope.tempEventSuggest =
                            $scope.plot.seriesData.eventQuery ||
                            $scope.plot.seriesData.detectorQuery;
                    }
                });

                // Toggle between plot name text and input
                $scope.hideLabelText = function () {
                    if (!$scope.editMode || $scope.plot.disabled) {
                        return;
                    }
                    if (!$scope.hasLabel) {
                        setPlotName(analyticsService.createPlotName($scope.plot));
                    }
                    // Swapping classes instead of using ng-class conditional on a
                    // scope variable, which would require adding a timeout here for
                    // the focus() to take effect
                    elem.find('.plot-label-text').addClass('sf-invisible');
                    elem.find('.plot-label-input').removeClass('sf-invisible').focus();
                };
                $scope.showLabelText = function () {
                    const labelInput = elem.find('.plot-label-input');
                    labelInput.addClass('sf-invisible');
                    // Check input value instead of scope variable because of debounce
                    // on input's ng-model-options
                    if (!labelInput.val() || labelInput.val().trim().length === 0) {
                        $scope.hasLabel = false;
                    }
                    elem.find('.plot-label-text').removeClass('sf-invisible');
                };

                $scope.setYAxis = function (idx) {
                    const targetIndex = idx;
                    $scope.passedUtils.addYAxis(idx);
                    $scope.plot.yAxisIndex = targetIndex;
                };

                $scope.getMapPlotType = function () {
                    const label = $scope.plot._originalLabel;
                    if ($scope.valueLabel === label) {
                        return 'value';
                    } else if ($scope.latitudeLabel === label) {
                        return 'latitude';
                    } else if ($scope.longitudeLabel === label) {
                        return 'longitude';
                    } else {
                        return 'n/a';
                    }
                };

                $scope.setMapPlotType = function (type) {
                    $scope.$emit('map-plot-type', $scope.plot._originalLabel, type);
                };

                $scope.setCompoundConditions = function (compoundSelected) {
                    if ($scope.compoundSelected !== compoundSelected) {
                        $scope.$emit('toggle compound conditions', compoundSelected);
                    }
                };

                function altModeHandler(altModeOn = false) {
                    // Sent for the plot-editor link function
                    $scope.$emit('plot alt mode trigger', $scope.plot.uniqueKey, altModeOn);
                }

                $scope.togglePlotVisibility = function ($event) {
                    if (!$scope.plot.disabled) {
                        const isHeatmap = $scope.chartMode === 'heatmap';
                        if (isHeatmap) {
                            if (!$scope.plot.invisible) {
                                // disallow making plot invisible for heatmaps, works like a radio button instead
                                return;
                            } else {
                                plotUtils.updatePlotVisibility(
                                    $scope.chartMode,
                                    $scope.allPlots,
                                    $scope.plot
                                );
                            }
                        } else {
                            if ($event.altKey) {
                                userAnalytics.event('click', 'plot-toggle-visibility-alt');
                                altModeHandler(!$scope.altMode);
                            } else if (!$scope.altMode) {
                                userAnalytics.event('click', 'plot-toggle-visibility');
                                $scope.plot.invisible = !$scope.plot.invisible;
                                $scope.$emit('update plot state');
                            }
                        }
                    }
                };

                $scope.altOverlayClick = function ($event) {
                    altModeHandler($event.altKey && !$scope.plot.transient);
                    if ($event.altKey) {
                        userAnalytics.event('click', 'plot-toggle-visibility-overlay-alt');
                    } else {
                        userAnalytics.event('click', 'plot-toggle-visibility-overlay');
                    }
                };

                // Received from the plot-editor link function
                $scope.$on(
                    'plot alt mode apply',
                    function (event, altModeDisplayedKey, altModeOn = false) {
                        // Check if we are going from normal mode to alt mode
                        // This is the only time we want to save the state to revert to in normal mode
                        if (!$scope.altMode && altModeOn) {
                            $scope.previousInvisibility = $scope.plot.invisible;
                        }

                        $scope.altMode = altModeOn;

                        if ($scope.altMode) {
                            $scope.plot.invisible = $scope.plot.uniqueKey !== altModeDisplayedKey;
                        } else {
                            $scope.plot.invisible = $scope.previousInvisibility;
                        }
                    }
                );

                $scope.$watch('plot.name', function (nval, oval) {
                    if (nval && oval && nval !== oval) {
                        $scope.$emit('changed plot name');
                    }
                });
                $scope.$watch(
                    'plot',
                    function (newval, oldval) {
                        const generatedName = analyticsService.createPlotName($scope.plot);
                        if (
                            !generatedName ||
                            oldval.name !== analyticsService.createPlotName(oldval) ||
                            newval.name !== oldval.name
                        ) {
                            return;
                        }

                        $scope.plot.name = generatedName;
                    },
                    true
                );

                $scope.$watch('allPlots.length', function () {
                    $scope.updateExpressionValidation(
                        $scope.plot.expressionText,
                        $scope.allPlots,
                        $scope.plot.uniqueKey
                    );

                    if ($scope.allPlots.length === 1) {
                        $timeout(function () {
                            $scope.showSignalInput(true);
                        }, 100);
                    }
                });

                $scope.$watch('plot.type', function (newv, oldv) {
                    if (
                        $scope.plot.transient === true &&
                        angular.isDefined(newv) &&
                        angular.isDefined(oldv) &&
                        newv !== oldv
                    ) {
                        if ($scope.plot.type === 'plot') {
                            $scope.refocusMetricInput();
                            $scope.plot.expressionText = null;
                            $scope.tempExpressionText = null;
                        } else if ($scope.plot.type === 'ratio') {
                            $scope.refocusExpressionInput();
                            $scope.plot.seriesData.metric = null;
                        } else if ($scope.plot.type === 'event') {
                            //nothing to do here, yet
                        }
                    }
                });

                function setPlaceHolder() {
                    if ($scope.isDetectorPlot && $scope.allPlots.length === 1) {
                        $scope.placeholder = 'Enter a metric';
                    } else if (
                        $scope.plot.transient &&
                        ($scope.chartMode === 'heatmap' || $scope.chartMode === 'table')
                    ) {
                        $scope.placeholder = 'Metric';
                    } else if ($scope.chartMode === 'event') {
                        $scope.placeholder = 'Event';
                    } else if ($scope.plot.transient) {
                        $scope.placeholder = 'Metric or event';
                    }
                    setObjectType();
                }

                $scope.$watch('chartMode', function () {
                    setPlaceHolder();
                });

                $scope.$on('chartModeChanged', function (evt, mode) {
                    $scope.chartMode = mode;
                });

                function getDisabledStateFromOverrides() {
                    $scope.activeVariable = {};
                    ($scope.variables || [])
                        .concat($scope.sourceOverrides || [])
                        .forEach(function (v) {
                            $scope.activeVariable[(v.NOT ? '!' : '') + v.property] = true;
                        });
                }

                $scope.$watch('variables', getDisabledStateFromOverrides, true);
                $scope.$watch('sourceOverrides', getDisabledStateFromOverrides, true);
                getDisabledStateFromOverrides();

                setPlaceHolder();

                $scope.expandPlotConfiguration = function () {
                    elem.find('.plot-configuration-' + $scope.plot.uniqueKey)
                        .show()[0]
                        .scrollIntoView(false);
                    $scope.showPlotConfiguration = true;
                };

                $scope.collapsePlotConfiguration = function () {
                    if ($scope.chartConfigOption.$valid && $scope.checkNoDuplicates()) {
                        elem.find('.plot-configuration-' + $scope.plot.uniqueKey).hide();
                        $scope.showPlotConfiguration = false;
                    }
                };

                $scope.togglePlotConfiguration = function () {
                    if ($scope.showPlotConfiguration) {
                        $scope.collapsePlotConfiguration();
                    } else {
                        $scope.expandPlotConfiguration();
                    }
                };

                // Highlight plot row when hovering over its hamburger menu, to help
                // the user see which plot the action will be performed on
                $scope.highlightPlotRow = function () {
                    elem.find('.plot-row').addClass('highlighted');
                };
                $scope.unhighlightPlotRow = function () {
                    elem.find('.plot-row').removeClass('highlighted');
                };

                $scope.checkNoDuplicates = function () {
                    if (!$scope.canAlias) {
                        return true;
                    }
                    const aliasMap = {};
                    let noDupes = true;
                    angular.forEach(
                        $scope.plot.configuration.aliases,
                        function (aliasStr, nodeNum) {
                            if (aliasStr === null) {
                                aliasStr = 'node' + nodeNum;
                            }
                            if (aliasMap[aliasStr]) {
                                noDupes = false;
                                aliasMap[aliasStr]++;
                            } else {
                                aliasMap[aliasStr] = 1;
                            }
                        }
                    );
                    $scope.duplicateAliases = Object.keys(aliasMap)
                        .filter(function (key) {
                            return aliasMap[key] > 1;
                        })
                        .join(', ');

                    return noDupes;
                };

                $scope.removePlot = function (plot) {
                    const promise = $scope.validateDelete ? $scope.validateDelete(plot) : $q.when();
                    promise.then(function () {
                        $scope.passedUtils.removePlot(plot);
                    });
                };

                if (angular.isUndefined($scope.plot.configuration.extrapolationPolicy)) {
                    $scope.plot.configuration.extrapolationPolicy =
                        VALID_POLICIES.NULL_EXTRAPOLATION;
                }

                // We have deprecated some extrapolation policies, this transforms them
                // back to the default
                if (!VALID_POLICIES.hasOwnProperty($scope.plot.configuration.extrapolationPolicy)) {
                    $scope.plot.configuration.extrapolationPolicy =
                        VALID_POLICIES.NULL_EXTRAPOLATION;
                }

                if (angular.isUndefined($scope.plot.configuration.maxExtrapolations)) {
                    $scope.plot.configuration.maxExtrapolations = -1;
                }

                $scope.maxExtrapolations = {};
                if ($scope.plot.configuration.maxExtrapolations === -1) {
                    $scope.maxExtrapolations.option = 'infinite';
                    $scope.maxExtrapolations.custom = undefined;
                } else {
                    $scope.maxExtrapolations.option = 'custom';
                    $scope.maxExtrapolations.custom = $scope.plot.configuration.maxExtrapolations;
                }
                $scope.$watch('maxExtrapolations.option', function (nval) {
                    if (nval === 'infinite') {
                        $scope.plot.configuration.maxExtrapolations = -1;
                    } else {
                        $scope.plot.configuration.maxExtrapolations =
                            $scope.maxExtrapolations.custom;
                    }
                });

                $scope.$watch('maxExtrapolations.custom', function (nval) {
                    if (nval > 0) {
                        $scope.plot.configuration.maxExtrapolations = nval;
                    }
                });

                $scope.selectSignal = function () {
                    $scope.selectedSignal.plot = $scope.plot;
                    $scope.setCompoundConditions(false);
                };

                $scope.openMetricsSidebar = function () {
                    $scope.$emit('showMetricsSidebar');
                };

                $scope.setViewMenu = function (viewMenuOption) {
                    $scope.plot.configuration.viewMenu = viewMenuOption;
                };

                $scope.getPlotIconTooltip = function () {
                    if ($scope.plot.type === 'plot') {
                        return 'Metric';
                    } else if ($scope.plot.type === 'ratio') {
                        return 'Formula';
                    } else if ($scope.plot.seriesData.detectorQuery) {
                        return 'Alert event';
                    } else if (
                        $scope.plot.seriesData.detectorId ||
                        $scope.plot.seriesData.autodetectId
                    ) {
                        return 'Linked detector';
                    } else if ($scope.plot.seriesData.eventQuery) {
                        return 'Custom event';
                    }
                    return '';
                };

                function onDestroy() {
                    if (unregisterRouteWatchGroup) {
                        unregisterRouteWatchGroup();
                    }
                }
            },
        };
    },
]);
